<template>
  <section class="main-section">
    <main-section-header title="센서 관리" subtitle="센서 정보를 등록/수정/삭제할 수 있습니다." />
    <div class="container">
      <list-utility-menu
        :hasSearchInput="true"
        placeholder="센서 모델명, S/N 으로 검색하세요"
        @input="onChangeKeyword"
        @button="onMoveSearchedPage(1)"
        @keyupenter="onMoveSearchedPage(1)"
        :value="keyword"
      >
        <button-basic slot="button" color="blue" size="s" :width="113" :height="32" @click="onMoveSensorResister">
          <plus-icon />
          등록
        </button-basic>
      </list-utility-menu>
      <list
        :list="sensorList"
        :headerList="sensorHeaderList"
        is-possible-hover-list="isPossibleHoverList"
        @sort="onClickSort"
      >
        <li slot="list-body" v-for="(sensor, index) in sensorList" :key="index" @click="onMoveSensorDetail(sensor.id)">
          <span>
            {{ { type: currentCompany, sensorId: sensor.id } | formatSensorCode }}
          </span>
          <span>{{ sensor.modelName }}</span>
          <span>{{ sensor.serialNumber || '-' }}</span>
          <span>{{ sensor.cnt || '-' }}</span>
        </li>
        <p slot="no-list-description">{{ noListDescription }}</p>
      </list>
      <pagination
        v-if="totalPageCount > 1"
        :page="page"
        :totalPageCount="totalPageCount"
        :pageSize="pageSize"
        @setPageNum="setPageNum"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import ListUtilityMenu from '@/component/list/ListUtilityMenu';
import List from '@/component/list/List';
import ListHeaderData from './ListHeaderList.json';
import Pagination from '@/component/pagination/Pagination';
import noListDescription from '@/asset/js/noListDescription';

export default {
  name: 'SensorList',
  props: [],
  data() {
    return {
      sensorList: null,
      sensorHeaderList: ListHeaderData,
      page: 1,
      pageSize: 20,
      keyword: '',
      sortOrder: '',
      totalPageCount: 0,
      isRequestedDataWithKeyword: false,
    };
  },
  computed: {
    ...mapState('users', ['currentCompany']),
    noListDescription() {
      return noListDescription(this.isRequestedDataWithKeyword);
    },
  },
  created() {
    this.getSensors();
  },
  mounted() {},
  watch: {
    $route() {
      this.getSensors();
    },
  },
  methods: {
    ...mapActions('manufacturerSensors', ['GET_MANUFACTURER_SENSORS']),
    ...mapActions('factorySensors', ['GET_FACTORY_SENSORS']),
    setQueryParams() {
      this.page = this.$route.query.page || 1;
      this.keyword = this.$route.query.keyword || '';
      this.sortOrder = this.$route.query.sortOrder || '';
    },
    getSensors() {
      this.setQueryParams();
      this.sensorList = null;
      this.totalPageCount = 0;
      this.isRequestedDataWithKeyword = !!this.keyword;
      switch (this.currentCompany) {
        case 'M':
          this.getManufacturerSensors();
          break;
        case 'F':
          this.getFactorySensors();
          break;
      }
    },
    async getManufacturerSensors() {
      const manufacturerSensors = await this.GET_MANUFACTURER_SENSORS([
        this.page,
        this.pageSize,
        this.sortOrder,
        this.keyword,
      ]);
      this.sensorList = manufacturerSensors.result;
      this.totalPageCount = manufacturerSensors['metaData'].totalPageCount;
    },
    async getFactorySensors() {
      const factorySensors = await this.GET_FACTORY_SENSORS([this.page, this.pageSize, this.sortOrder, this.keyword]);
      this.sensorList = factorySensors.result;
      this.totalPageCount = factorySensors['metaData'].totalPageCount;
    },
    onMoveSensorResister() {
      this.$router.push(`sensors/register`).catch((err) => err);
    },
    onMoveSensorDetail(gid) {
      this.$router.push(`sensors/${gid}`).catch((err) => err);
    },
    onMoveSearchedPage(page) {
      this.$router
        .push({
          query: {
            page: page || this.page || undefined,
            pageSize: this.pageSize || undefined,
            sortOrder: this.sortOrder || undefined,
            keyword: this.keyword || undefined,
          },
        })
        .catch((err) => err);
    },
    onChangeKeyword(changeKeyword) {
      this.keyword = changeKeyword;
    },
    onClickSort(sort) {
      if (this.sortOrder) {
        this.sortOrder = `${this.sortOrder.indexOf('-') === 0 ? '' : '-'}${sort}`;
      } else {
        this.sortOrder = sort;
      }
      this.onMoveSearchedPage();
    },
    setPageNum(page) {
      this.page = page;
      this.onMoveSearchedPage();
    },
  },
  components: { MainSectionHeader, ListUtilityMenu, List, Pagination },
};
</script>

<style scoped lang="scss">
@import 'SensorList';
</style>
