var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section" },
    [
      _c("main-section-header", {
        attrs: {
          title: "센서 관리",
          subtitle: "센서 정보를 등록/수정/삭제할 수 있습니다.",
        },
      }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "list-utility-menu",
            {
              attrs: {
                hasSearchInput: true,
                placeholder: "센서 모델명, S/N 으로 검색하세요",
                value: _vm.keyword,
              },
              on: {
                input: _vm.onChangeKeyword,
                button: function ($event) {
                  return _vm.onMoveSearchedPage(1)
                },
                keyupenter: function ($event) {
                  return _vm.onMoveSearchedPage(1)
                },
              },
            },
            [
              _c(
                "button-basic",
                {
                  attrs: {
                    slot: "button",
                    color: "blue",
                    size: "s",
                    width: 113,
                    height: 32,
                  },
                  on: { click: _vm.onMoveSensorResister },
                  slot: "button",
                },
                [_c("plus-icon"), _vm._v(" 등록 ")],
                1
              ),
            ],
            1
          ),
          _c(
            "list",
            {
              attrs: {
                list: _vm.sensorList,
                headerList: _vm.sensorHeaderList,
                "is-possible-hover-list": "isPossibleHoverList",
              },
              on: { sort: _vm.onClickSort },
            },
            [
              _vm._l(_vm.sensorList, function (sensor, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    attrs: { slot: "list-body" },
                    on: {
                      click: function ($event) {
                        return _vm.onMoveSensorDetail(sensor.id)
                      },
                    },
                    slot: "list-body",
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatSensorCode")({
                              type: _vm.currentCompany,
                              sensorId: sensor.id,
                            })
                          ) +
                          " "
                      ),
                    ]),
                    _c("span", [_vm._v(_vm._s(sensor.modelName))]),
                    _c("span", [_vm._v(_vm._s(sensor.serialNumber || "-"))]),
                    _c("span", [_vm._v(_vm._s(sensor.cnt || "-"))]),
                  ]
                )
              }),
              _c(
                "p",
                {
                  attrs: { slot: "no-list-description" },
                  slot: "no-list-description",
                },
                [_vm._v(_vm._s(_vm.noListDescription))]
              ),
            ],
            2
          ),
          _vm.totalPageCount > 1
            ? _c("pagination", {
                attrs: {
                  page: _vm.page,
                  totalPageCount: _vm.totalPageCount,
                  pageSize: _vm.pageSize,
                },
                on: { setPageNum: _vm.setPageNum },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }